export type FiscalYearLeadCount = { [key: string]: number } & { label: string }
type MonthWithFiscalYears = { [key: string]: number } & { label: string }

export const monthNames = ['July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun']

export function transformLeadData(data: FiscalYearLeadCount[]): MonthWithFiscalYears[] {
	// Create an array to store the transformed data
	const transformed = []

	// Loop through each month
	for (const month of monthNames) {
		// Create a new object for the current month
		const monthObj = { label: month } as MonthWithFiscalYears

		// Loop through each data object
		for (const obj of data) {
			// Add the value for the current month to the new object
			monthObj[obj.label] = obj[month]
		}

		// Add the new object to the transformed array
		transformed.push(monthObj)
	}

	return transformed
}
